import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import { gistBehaviour } from './scripts/gistBehaviour';

export { wrapRootElement } from './src/apollo/wrap-root-element';

export const onClientEntry = () => {
  gistBehaviour();

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };
};
