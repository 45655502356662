import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      borderRadius: '2px',
      color: palette.text.primary,
      fontSize: '15px',
      lineHeight: '18px',
      minHeight: '42px',
      padding: '0px',
      '& fieldset': {
        borderColor: palette.text.secondary,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.info.main,
        borderWidth: '1px',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.info.main,
        borderWidth: '1px',
      },
      '&.Mui-disabled': {
        background: palette.background.default,
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.text.disabled,
      },
    },
    input: {
      padding: '12px 20px',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',

      '&:-webkit-autofill': {
        WebkitTextFillColor: '#ссс',
        WebkitBoxShadow: `0 0 0 1000px transparent inset`,
        transition: 'background-color 5000s ease-in-out 0s',
        background:
          '-webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%)',
      },
    },
  },
};
