import palette from '../palette';

export default {
  defaultProps: {
    size: 'large',
  },
  styleOverrides: {
    root: {
      borderRadius: '25px',
      textTransform: 'none',
      fontWeight: 'normal',
      lineHeight: '16px',
      fontSize: '15px',
      boxShadow: 'none',
    },
    contained: {
      borderRadius: '12px',
      color: palette.common.white,
      background: palette.info.light,
      padding: '12px 32px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      height: '48px',
      '&:hover': {
        background: palette.info.light,
        boxShadow: 'none',
      },
      '&:active': {
        background: palette.info.light,
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        color: '#F8F8F8',
        background: palette.action.disabledBackground,
      },
    },
    outlined: {
      color: palette.secondary.main,
      backgroundColor: palette.common.white,
      border: 'none',
      span: {
        display: 'inline',
        background: palette.secondary.dark,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
      '&.Mui-disabled': {
        color: palette.action.disabled,
        backgroundColor: palette.action.disabledBackground,
        borderRadius: '12px',
        height: '42px',
        span: {
          display: 'inline',
          background: palette.action.disabledBackground,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          marginLeft: '32px',
        },
      },
      '&:hover': {
        backgroundColor: palette.common.white,
        border: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        span: {
          display: 'inline',
          background: palette.secondary.main,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        },
      },
    },
    outlinedSizeSmall: {
      minWidth: '20px',
      height: '20px',
      padding: '13px 8px',
      span: {
        display: 'inline',
        fontWeight: '500',
        fontSize: '20px',
        background: palette.secondary.dark,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    outlinedSizeMedium: {
      minWidth: '42px',
      padding: '12px 16px',
      span: {
        display: 'inline',
        fontWeight: '500',
        fontSize: '18px',
        background: palette.secondary.dark,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    },
    outlinedSizeLarge: {
      height: '40px',
      padding: '12px 30px',
    },
  },
};
