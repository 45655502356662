import palette from '../palette';

export default {
  styleOverrides: {
    input: {
      padding: '3px 5px 3px 5px !important',
    },
    clearIndicator: {
      color: palette.secondary.main,
    },
  },
};
