import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      color: palette.text.secondary,
      '&[data-shrink="true"]': {
        marginTop: '-10px',
        color: palette.text.primary,
        marginLeft: '7px',
      },
      '&[data-shrink="false"]': {
        marginTop: '-5px',
      },
      '&.Mui-focused': {
        color: palette.info.main,
      },
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
  },
};
