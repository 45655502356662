import { Palette } from '@mui/material';

export default {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  primary: {
    light: 'linear-gradient(270deg, rgba(255, 180, 114, 0.2) 0%, rgba(242, 102, 81, 0.2) 100%)',
    main: 'rgba(255, 255, 255, 0.5)',
    dark: 'rgba(255, 255, 255, 0.3)',
    contrastText: 'rgba(255, 180, 114, 0.2)',
  },
  secondary: {
    main: '#F16152',
    dark: 'linear-gradient(90deg, #F16152 0%, #F99746 100%)',
    light: 'linear-gradient(90deg, #FFA399 0%, #FFC18E 100%)',
    contrastText: '#FFECDC',
  },
  warning: {
    main: '#FCD14F',
  },
  info: {
    main: '#FFB472',
    light: '#FEA351',
    dark: '#F2994A',
    contrastText: '#F1F5F9',
  },
  success: {
    main: 'rgba(16, 16, 45, 0.2)',
    light: '#BBC9DF',
    dark: '#F5F6F6',
    contrastText: '#F4F5F5',
  },
  error: {
    main: '#DA4848',
  },
  text: {
    primary: '#10102D',
    secondary: 'rgba(16, 16, 45, 0.3)',
    disabled: '#626275',
  },
  background: {
    default: '#F1F2F2',
  },
  action: {
    disabled: '#FFE9D6',
    selected: '#E7ECF6',
    disabledBackground: '#FFCCA0',
    disabledOpacity: 1,
  },
} as Palette;
