export const gistBehaviour = () => {
  document.addEventListener('gistReady', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gist.chat('hide');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gist.chat('hideLauncher');
  });

  document.addEventListener('gistChatReady', () => {
    const gistWidget = document.getElementsByClassName('gist-messenger-iframe')[0] as HTMLElement;
    const gistBtn = document.getElementById('gist-button');
    const scrollToTopBtn = document.getElementById('scroll-to-top-button');

    const setWidgetPosition = (scrollToTopBtn: HTMLElement | null) => {
      gistWidget.style.bottom = scrollToTopBtn ? '150px' : '80px';
      gistWidget.style.height = `calc(100% - ${scrollToTopBtn ? '160px' : '90px'})`;
    };

    if (gistWidget && gistBtn) {
      gistBtn.classList.add('show');

      scrollToTopBtn ? gistBtn.classList.add('top') : gistBtn.classList.remove('top');

      setWidgetPosition(scrollToTopBtn);
    }

    const handleGistBtnClick = () => {
      const scrollToTopBtn = document.getElementById('scroll-to-top-button');
      const isOpen = gistBtn?.classList.contains('open');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gist.chat(isOpen ? 'close' : 'open');

      gistBtn?.classList.toggle('open');

      setWidgetPosition(scrollToTopBtn);
    };

    const handleScroll = () => {
      const scrollToTopBtn = document.getElementById('scroll-to-top-button');

      if (gistWidget && gistBtn) {
        scrollToTopBtn ? gistBtn.classList.add('top') : gistBtn.classList.remove('top');

        setWidgetPosition(scrollToTopBtn);
      }
    };

    document.addEventListener('scroll', handleScroll);
    document.addEventListener(
      'messenger:closed',
      () => {
        gistBtn?.classList.toggle('open');
      },
      false,
    );
    gistBtn?.addEventListener('click', handleGistBtnClick);
  });
};
