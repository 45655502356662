import palette from '../palette';

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      width: '18px',
      height: '18px',
      marginRight: '10px',
      color: palette.text.primary,
      opacity: 0.3,
      '&.Mui-checked': {
        color: palette.info.light,
        opacity: 1,
        '&:hover': {
          background: 'none',
        },
      },
      '&:hover': {
        background: 'none',
      },
    },
  },
};
