export default {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: {
      '&:hover': {
        background: 'none',
      },
    },
  },
};
